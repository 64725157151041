import React from 'react'
import classes from './TangleComponents.module.css';

export default function TangleBasicButton({ children, onClick, active, style }) {
  return (
    <span style={style} className={`${classes.buttonBasic} ${active ? classes.active : ""}`} onClick={onClick}>
      {children}
    </span>
  )
}
