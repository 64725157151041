import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Header from "./components/Header";
import SwipeableViews, { useSwipeable } from "./components/SwipableView";
import TangleBasicButton from "./components/TangleBasicButton";
import SansController from "./pages/SansController";
import Animation from "./pages/Animation";
import Interactive from "./pages/Interactive";
import classes from "./pages/SansController.module.css";
import { TangleConnection, TangleConnectionProvider } from "./components/TangleConnectionContext";
import { tangleDevice } from "./lib/utils/communication";
import Remote from "./pages/Remote";

export default function Routing() {
  const [index, setIndex] = useState(0);

  return (
    <Router>
      <TangleConnectionProvider>
        <Header pageIndex={index} />
        <br />
        <br />
        <div style={{ display: "flex", justifyContent: "center", width: "100%", alignSelf: "flex-end", marginTop: "auto", marginBottom: 20 }}>
          <TangleBasicButton style={{ margin: 20, marginTop: 0, padding: 10 }} active={index === 0} onClick={(_) => setIndex(0)}>
            Controller
          </TangleBasicButton>
          <TangleBasicButton style={{ margin: 20, marginTop: 0, padding: 10 }} active={index === 1} onClick={(_) => setIndex(1)}>
            Animation
          </TangleBasicButton>
          <TangleBasicButton style={{ margin: 20, marginTop: 0, padding: 10 }} active={index === 2} onClick={(_) => setIndex(2)}>
            Interactive
          </TangleBasicButton>
          <TangleBasicButton style={{ margin: 20, marginTop: 0, padding: 10 }} active={index === 3} onClick={(_) => setIndex(3)}>
            Remote
          </TangleBasicButton>
        </div>

        <SwipeableViews index={index} onChangeIndex={setIndex}>
          <div style={{ height: "800x", width: "100%", background: "black" }}>
            <SansController />
          </div>
          <div style={{ height: "800x", width: "100%", background: "black" }}>
            <Animation />
          </div>
          <div style={{ height: "800x", width: "100%", background: "black" }}>
            <Interactive />
          </div>
          <div style={{ height: "800x", width: "100%", background: "black" }}>
            <Remote isCurrent={index === 3} />
          </div>
        </SwipeableViews>
      </TangleConnectionProvider>
    </Router>
  );
}
