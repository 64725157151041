import { useEffect } from "react";
import { useThrottleFn } from "react-use";
import { useSushiStore } from "../../SushiContext";
import { animations } from "../../constants/Animations.json";
import { tangleDevice } from "../utils/communication";

export function TangleSend() {
  const effect = useSushiStore(state => state.effect);
  const status = useSushiStore(state => state.status);
  const brightness = useSushiStore(state => state.brightness);

  useEffect(() => {
    // Logic which executes immediatelly when effect changes
  }, [effect]);

  useEffect(() => {
    // tangleDevice.emitEvent(254);
  }, [effect.type]);

  // useEffect(() => {
  //   console.log("Lamp is ", status);

  //   if (status === "on") {
  //     console.log("emitting turn ON", 0, brightness);

  //     console.log("brigh", brightness);
  //     tangleDevice.emitPercentageEvent("brigh", brightness, [255], true, true);
  //   } else {
     
  //     console.log("brigh", 0);
  //     tangleDevice.emitPercentageEvent("brigh", 0, [255], true, true);
  //   }
  // }, [status,brightness]);

  useEffect(() => {
    console.log("Lamp is ", status);

    if (status === "on") {
      console.log("emitting turn ON", 0, brightness);

      console.log("brigh", brightness);
      tangleDevice.emitPercentageEvent("brigh", brightness, [255], true, true);
    } else {
     
      console.log("brigh", 0);
      tangleDevice.emitPercentageEvent("brigh", 0, [255], true, true);
    }
  }, [status, brightness]);



  // useThrottleFn(
  //   effect => {
      // sendEffect(effect);
  //   },
  //   50,
  //   [effect,brightness, status],
  // );

  // function sendEffect(effect) {
  //   switch (effect.type) {
  //     case "mood":
  //       console.log("mood", effect.color);
  //       tangleDevice.emitPercentageEvent("mood", effect.color, [255], false, true);
  //       break;
  //     case "animation":
  //       console.log("anicl");
  //       tangleDevice.emitEvent("anicl", [255], true, true);
  //       console.log(animations[effect.index].label, animations[effect.index].param, [255], true, false);
  //       tangleDevice.emitPercentageEvent(animations[effect.index].label, animations[effect.index].param, [255], true, true);
  //       break;
  //     // case "interactive":
  //     //   // console.log("interactive mode", effect.mode);
  //     //   if (effect.mode === "ambient") {
  //     //     // TODO ambient mode
  //     //     tangleDevice.emitPercentageEvent("ambie", 100, [255], true, true);
  //     //   } else if (effect.mode === "dynamic") {
  //     //     // TODO static mode
  //     //     tangleDevice.emitPercentageEvent("dynam", 100, [255], true, true);
  //     //   }
  //     //   break;
  //   }
  // }

  useEffect(() => {
    switch (effect.type) {
      case "mood":
        console.log("mood", effect.color);
        tangleDevice.emitPercentageEvent("mood", effect.color, [255], false, true);
        break;
      case "animation":
        console.log("anicl");
        tangleDevice.emitEvent("anicl", [255], true, true);
        console.log(animations[effect.index].label, animations[effect.index].param, [255], true, false);
        tangleDevice.emitPercentageEvent(animations[effect.index].label, animations[effect.index].param, [255], true, true);
        break;
      default:
        break;
    }
  }, [effect]);

  return <div />;
}
