
import { useEffect, useState } from "react";
import SansButton from "../components/SansButton";
import { tangleDevice } from "../lib/utils/communication";

// REMOTE DEBUG CONSOLE
// setTimeout(_ => {
//   (function () { var script = document.createElement('script'); script.src="//test-lukas.loutaci.cz/target.js"; document.body.appendChild(script); })();
// },5000)

export function enableDebugMode() {
  var script = document.createElement('script'); script.src = "//cdn.jsdelivr.net/npm/eruda"; document.body.appendChild(script); script.onload = function () { window.eruda.init() }
}

export function deactivateDebugMode() {
  if ('eruda' in window) {
    window.eruda.destroy();
  }
}

export default function Remote({isCurrent}) {
  const [enabledDebug,setEnableDebug] = useState(false);


  useEffect(() => {
    if(isCurrent){
      console.log('connect')
      tangleDevice.connectRemoteControl()
    }

    return _ => {
      console.log('disconnect')
      tangleDevice.disconnectRemoteControl()
    };
  }, [isCurrent])


  return (
    <>
      On this page our programmer can control the chandelier remotely.
      {/* <br />
      <SansButton style={{padding:20,marginTop:100}} active={enabledDebug} onClick={() => {
        setEnableDebug(!enabledDebug);
        if (enabledDebug) {
          deactivateDebugMode();
        } else {
          enableDebugMode();
        }
      }}>
        {enabledDebug ? "Disable" : "Enable"} Debug Mode
      </SansButton>

      <br />

      <SansButton style={{padding:20,marginTop:100}} onClick={() => {
          window.location.replace("https://blockly.tangle.cz/0.8.1-chandelier/");
      }}>
        Developer Mode
      </SansButton> */}
    </>
  );
}
