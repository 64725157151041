import React, { useEffect, useState } from 'react'

export default function AppUpdateService() {
  const [reloadShow, setReloadShow] = useState(false)

  useEffect(() => {
    const bc = new BroadcastChannel('internal_notification');
    bc.onmessage = () => setReloadShow(true);
    return () => {
      bc.close();
    }
  }, [])

  return (
    reloadShow ?
      <div style={{ position: 'fixed', bottom: 0, left: 0, width: "100%", padding: 19, background: "#222",zIndex:100000 }}>
        New version ready <a href="/" style={{ color: "white", fontWeight: "bolder" }}>Reload App</a>
      </div> : null
  )
}
