import logo from "./logo.svg";
import "./App.css";
import Routing from "./Routing";
import { TangleSend } from "./lib/utils/TangleSend";
import AppUpdateService from "./AppUpdateService";

function App() {
  return (
    <div className="App" style={{ height: "100vh" }}>
      <AppUpdateService />
      <Routing />
      <TangleSend />
    </div>
  );
}

export default App;
