import { sleep } from "../tangle-js/functions.js";
import { TangleDevice } from "../tangle-js/TangleDevice.js";
import {TangleMsgBox} from '../webcomponents/dialog-component'


export const tangleDevice = new TangleDevice(undefined,0);

tangleDevice.assignOwnerSignature("1fc8536818fe0f6c9b2c61f70d4d7dbc");
tangleDevice.assignOwnerKey("bfd39c89ccc2869f240508e9a0609420");

window?.tangleConnect && tangleDevice.connect();

async function reconnect(){
  try {
    const connected = await tangleDevice.connected()
    if(!connected){
      await tangleDevice.connect()
    }
  } finally {
    await sleep(10000)
    reconnect()
  }
}

window?.tangleConnect && reconnect();

window.tangleDevice = tangleDevice;

window.prompt = TangleMsgBox.prompt;
window.alert = TangleMsgBox.alert;
window.confirm = TangleMsgBox.confirm;
