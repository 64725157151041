import SansButton from "../components/SansButton";
import { TangleInput } from "../components/TangleComponents";
import { tangleDevice } from "../lib/utils/communication";
import { useSushiStore } from "../SushiContext";
import classes from "./SansController.module.css";

export default function SansController() {
  const state = useSushiStore((state) => state);
  


  return (
    <>
      <TangleInput
        defaultValue={50}
        type="range"
        min="0"
        max="100"
        step="1"
        value={state.effect?.color}
        className={`${classes.input}`}
        style={{ maxWidth: 600, marginTop: 40 }}
        onChange={(e) => {
          state.setEffect({ type: "mood", color: e.target.value });
        }}
      />
      <small style={{}}>{state.effect?.color}%</small>

      <TangleInput
        defaultValue={60}
        type="range"
        min="20"
        max="100"
        step="1"
        value={state.status === "on" ? state.brightness : 0 }
        className={`${classes.input} ${classes.inputBrightness}`}
        style={{ maxWidth: 600, marginTop: 60 }}
        onChange={(e) => {
          const brigh = Number(e.target.value);
          state.setStatus('on');
          state.setBrightness(brigh);
        }}
      />
      <small style={{}}>{state.status === "on" ? state.brightness : 0}%</small>

      <div>
        <SansButton
          active={state.brightness > 0 && state.status === "on"}
          onClick={(_) => state.setStatus(state.status === "on" ? "off" : "on")}
          style={{ padding: "25px 80px", marginTop: 30, fontSize: 25 }}
        >
          {(state.status === "on" && state.brightness) ? "Turn Off" : "Turn On"}
        </SansButton>
      </div>
    </>
  );
}
