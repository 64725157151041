import { Link } from "react-router-dom";
import classes from "./TangleComponents.module.css";

const buttonColors = {
  primary: classes.primary,
  secondary: classes.secondary,
  danger: classes.danger,
};

export function TangleButton({ style, children, content, onClick, color }) {
  return (
    <div className="">
      <button className={`${classes.button} ${buttonColors[color || "primary"]}`} style={style} onClick={onClick}>
        {children || content}
      </button>
    </div>
  );
}

export function TangleContainer({ children, style, content, onClick, color }) {
  return (
    <div className={classes.container} style={style}>
      {children}
    </div>
  );
}

export function TangleInput({ type, style, placeholder, content, onClick, onChange, onTouchStart, value, min, max, step, className }) {
  const preventFunc = (e) => {};
  const prevent = {
    onTouchStart: preventFunc,
    onTouchStartCapture: preventFunc,
    onTouchCancel: preventFunc,
    onTouchCancelCapture: preventFunc,
    onTouchMove: preventFunc,
    onTouchMoveCapture: preventFunc,
    onTouchEnd: preventFunc,
    onTouchEndCapture: preventFunc,
  };
  return <input className={`${classes.input} ${className}`} style={style} {...{ onClick, onChange, value, placeholder, type, min, max, step }} {...prevent}></input>;
}

export function TangleLink({ to, children, style }) {
  return (
    <Link to={to}>
      <div style={{ ...style, color: "white", padding: 10, background: "rgba(255,255,255,0.1)", borderRadius: 10, margin: 5 }}>{children}</div>
    </Link>
  );
}
