import { useEffect, useState } from "react";
import SansButton from "../components/SansButton";
import { TangleInput } from "../components/TangleComponents";
import classes from "./Animation.module.css";

import { animations } from "../constants/Animations.json";
import { useSushiStore } from "../SushiContext";

export default function Animation() {
  const sushi = useSushiStore(state => state);
  const [activeButton, setActiveButton] = useState(0);
  const [buttons, setButtons] = useState(animations);

  function setActive(v, k) {
    setActiveButton(k);
    sushi.setEffect({
      type: "animation",
      index: k,
    });
  }

  return (
    <div className={classes.animationGrid}>
      {buttons.map((v, k) => (
        <SansButton key={k} active={k === activeButton && sushi.status === "on"} onClick={_ => setActive(v, k)}>
          { animations[k].name }
        </SansButton>
      ))}
    </div>
  );
}
