import { useContext, useEffect } from "react";
import TANGLE_CODE from "../constants/TANGLE_CODE";
import { tangleDevice } from "../lib/utils/communication";
import classes from "./Header.module.css";
import { TangleButton } from "./TangleComponents";
import { TangleConnection } from "./TangleConnectionContext";

export default function Header({ pageIndex }) {
  const {connectionStatus,connect,disconnect} = useContext(TangleConnection);


  useEffect(() => {
    if (connectionStatus === "connected") {
      // pagex se posila nyni pri pripojeni ci pri prepnuti stranky
      console.log(`page${pageIndex + 1}`, 100)
      tangleDevice.emitPercentageEvent(`page${pageIndex + 1}`, 100, [255], true, true);
    }
    // console.log(connectionStatus, "page" + (pageIndex + 1))
  }, [pageIndex, connectionStatus])

  return (
    <header className={classes.header}>
      {
        !window?.tangleConnect && (
          <TangleButton
            style={{ position: "fixed", top: 20, right: 20,zIndex:10001 }}
            variant="contained"
            color={connectionStatus === "disconnected" ? "primary" : "danger"}
            onClick={connectionStatus === "disconnected" ? _ => connect() : _ => disconnect()}
          >
            {connectionStatus === "connected" && "Disconnect"}
            {connectionStatus === "disconnected" && "Connect"}
          </TangleButton>
        )
      }
    {connectionStatus === "disconnected" && <div style={{zIndex:10000,position:"absolute",top:0,left:0,right:0,bottom:0,background:"rgba(0,0,0,0.8)",display:"flex",justifyContent:"center",alignItems:"center"}}>
        <div style={{}}>
          Connection lost, please go closer to the chandelier
        </div>
      </div>}
    </header>
  );
}
