import create from "zustand";

export const useSushiStore = create(set => ({
  effect: {
    type: "none",
    id: 1,
    color: 50,
    index: 1,
    mode: "control",
  },
  status: "off",
  brightness: 60,
  setEffect: effect => set({ effect, status: "on" }),
  setEffectWithoutEnabling: effect => set({ effect }),
  /**
   * @param {('on'|'off')} status
   */
  setStatus: status => set({ status }),
  /**
   * @param {number} brightness
   */
  setBrightness: brightness => set({ brightness }),
  /**
   * @param {'control'|'ambient'|'dynamic'} brightness
   */
  setMode: mode => set({ mode }),
}));
