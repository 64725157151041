import classes from "./Interactive.module.css";
import music from "../assets/music.svg";
import SansButton from "../components/SansButton";
import { TangleInput } from "../components/TangleComponents";
import { useSushiStore } from "../SushiContext";
import { useState } from "react";
import { tangleDevice } from "../lib/utils/communication";

export default function Interactive() {
  const sushi = useSushiStore(state => state);

  const [isReactive, setIsReactive] = useState(false);
  const [mode, setMode] = useState("ambient");
  

  // const interactiveActive = sushi.status === "on";

  function setActive() {
    if(!isReactive) {
      toggleMode();
    } else {
      console.log("reacl");
      tangleDevice.emitEvent("reacl", [255], true, true);
    }

    console.log("recompile netlify");

    // console.log("react", !isReactive ? 100 : 0);
    // tangleDevice.emitPercentageEvent("react", !isReactive ? 100 : 0, [255], true, true);
    setIsReactive(!isReactive);
    // sushi.setEffect({ type: "interactive" });
  }

  function toggleMode() {
    console.log("reacl");
    tangleDevice.emitEvent("reacl", [255], true, true);
    const toggledMode = mode === "ambient" ? "dynamic" : "ambient";
    console.log(toggledMode.slice(0, 5), 100);
    tangleDevice.emitPercentageEvent(toggledMode.slice(0, 5), 100, [255], true, true);
    setMode(toggledMode);
  }

  return (
    <div>
      <img className={classes.music} src={music} style={{ width: 250, padding: 10, paddingBottom: 30 }} />
      <div className="">
        <SansButton style={{ padding: 20, fontSize: 24 }} active={isReactive} onClick={setActive}>
          {isReactive ? "Stop" : "Start"}
        </SansButton>
        <SansButton style={{ padding: 20, fontSize: 24, marginLeft: 30, textTransform: "capitalize" }} onClick={toggleMode}>
          {mode}
        </SansButton>
      </div>

      <p style={{ fontSize: 16, marginTop: 30 }}>Start playing and the animation will show up</p>
    </div>
  );
}
