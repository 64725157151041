import { createContext, useEffect, useState } from "react";
import { tangleDevice } from "./../lib/utils/communication";

const TangleConnection = createContext();

const TangleConnectionProvider = ({ children }) => {
  const [connectionStatus, setConnectionStatus] = useState("disconnected");

  useEffect(() => {
    tangleDevice.on("connected", event => {
        setConnectionStatus("connected");
    });

    tangleDevice.on("disconnected", event => {
      setConnectionStatus("disconnected");
    });
  }, []);

  function connect() {
    tangleDevice.connect().catch(e => { });
  }

  function disconnect() {
    tangleDevice.disconnect();
  }

  return <TangleConnection.Provider value={{ connectionStatus, connect, disconnect }}>{children}</TangleConnection.Provider>;
};

export { TangleConnectionProvider, TangleConnection };

